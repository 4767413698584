
import { defineComponent } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import EUIBasicUsage from "@/views/resources/documentation/element-ui/navigation/tabs/BasicUsage.vue";
import EUICardStyle from "@/views/resources/documentation/element-ui/navigation/tabs/CardStyle.vue";
import EUIBorderCard from "@/views/resources/documentation/element-ui/navigation/tabs/BorderCard.vue";
import EUITabPosition from "@/views/resources/documentation/element-ui/navigation/tabs/TabPosition.vue";
import EUICustomTab from "@/views/resources/documentation/element-ui/navigation/tabs/CustomTab.vue";
import EUIAddAndCloseTab from "@/views/resources/documentation/element-ui/navigation/tabs/AddAndCloseTab.vue";
import EUICustomizedTriggerButtonOfNewTab from "@/views/resources/documentation/element-ui/navigation/tabs/CustomizedTriggerButtonOfNewTab.vue";

export default defineComponent({
  name: "tabs",
  components: {
    EUIBasicUsage,
    EUICardStyle,
    EUIBorderCard,
    EUITabPosition,
    EUICustomTab,
    EUIAddAndCloseTab,
    EUICustomizedTriggerButtonOfNewTab
  },
  setup() {
    setCurrentPageTitle("Tabs");
  }
});
